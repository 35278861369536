import React from 'react';
import { Provider } from "react-redux";
import store from "./store";
import Main from "./_Main";

// const reduxStore = configureStore(window.REDUX_INITIAL_DATA);
const reduxStore = store

export default function App() {
    return (
        <Provider store={reduxStore}>
            <Main/>
        </Provider>
    )
}

import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {checkUser}  from "Network/authentication";
import * as A from "constants/actions";

function* checkAuth(action) {
    console.log("IN HERE");
    const user = checkUser()
        .then(response => {
            console.log("RESPONSE HERE:");
        })
        .catch(error => {
            console.error("Issue checking user", error);
        })
    if (!user === undefined) {
        yield put({
            type: A.SHOW_LOGIN_SCREEN,
            payload: false
        })
        yield put({
            type: A.COGNITO_USER,
            payload: user
        })
    }
    else {
        const allcookies = document.cookie;
        const cookiearray = allcookies.split(";");
        let domain = window.location.hostname;
        if (domain !== "localhost") {
            domain = "." + domain;
        }
        for (var i = 0; i < cookiearray.length; i++) {
            let name = cookiearray[i].split("=")[0];
            let value = cookiearray[i].split("=")[1];
            let toCheck = name.split(".");
            if (toCheck[0] === " CognitoIdentityServiceProvider") {
                document.cookie = `${name.slice(
                    1
                )}=; Path=/; Domain=${domain}; Expires=Wed, 21 Oct 2010 07:28:00 GMT;`;
            } else if (toCheck[0] === "CognitoIdentityServiceProvider") {
                document.cookie = `${name}=; Path=/; Domain=${domain}; Expires=Wed, 21 Oct 2010 07:28:00 GMT;`;
            }
        }
    }
}


export function* watchCheckAuth() {
    yield takeLatest(A.CHECK_USER, checkAuth);
}
import {Auth} from "aws-amplify";

export const checkUser = () => {
    return Auth.currentSession()
    .then(response => {
        return Auth.currentAuthenticatedUser({
            // bypassCache: false
        })
    }).catch(err => {
        console.log("Error checking user: ", err);
    })
}
import Login from "./Login";
import { connect} from "react-redux";
import * as A from "../../constants/actions";

const mapStateToProps = state => ({
    items: state.items,
    cognitoUser: state.cognitoUser,
});

const mapDispatchToProps = dispatch => ({
    setCognitoUser: ((user) => {
        dispatch({
            type: A.COGNITO_USER,
            payload: user
        })
    }),
    setShowLoginScreen: ((payload) => {
        dispatch({
            type: A.SHOW_LOGIN_SCREEN,
            payload
        })
    })
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);
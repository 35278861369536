import React, { useState, Fragment } from "react";
import "./NewCustomer.css";
import { createCustomers1 } from "graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import NewInvoice from "views/NewInvoice/_NewInvoice";

export default function NewCustomer(props) {
    // presentation
    const [showBilling, setShowBilling] = useState(true);
    const [showWorkLocation, setShowWorkLocation] = useState(false);
    const [showBillingConfirmation, setshowBillingConfirmation] = useState(false);
    const [showWorkConfirmation, setShowWorkConfirmation] = useState(false);
    const [showImportFromBilling, setShowImportFromBilling] = useState(false);
    const [showNewInvoice, setShowNewInvoice] = useState(false);

    // input values
    const [billingFirstName, setBillingFirstName] = useState("");
    const [billingLastName, setBillingLastName] = useState("");
    const [billingAddress, setBillingAddress] = useState("");
    const [billingCity, setBillingCity] = useState("");
    const [billingState, setBillingState] = useState("");
    const [billingZip, setBillingZip] = useState("");
    const [billingPhone, setBillingPhone] = useState("");
    const [billingEmail, setBillingEmail] = useState("");
    const [workFirstName, setWorkFirstName] = useState("");
    const [workLastName, setWorkLastName] = useState("");
    const [workAddress, setWorkAddress] = useState("");
    const [workCity, setWorkCity] = useState("");
    const [workState, setWorkState] = useState("");
    const [workZip, setWorkZip] = useState("");
    const [workPhone, setWorkPhone] = useState("");
    const [workEmail, setWorkEmail] = useState("");
    const [invoice, setInvoice] = useState();
    const [showSuccess, setShowSuccess] = useState(false);

    const handleOnChangeBilling = (e) => {
        if (!showBillingConfirmation) {
            const value = e.target.value;
            switch (e.target.name) {
                case "billingFirstName":
                    setBillingFirstName(value);
                    break;
                case "billingLastName":
                    setBillingLastName(value);
                    break;
                case "billingAddress":
                    setBillingAddress(value);
                    break;
                case "billingCity":
                    setBillingCity(value);
                    break;
                case "billingState":
                    setBillingState(value);
                    break;
                case "billingZip":
                    setBillingZip(value);
                    break;
                case "billingPhone":
                    setBillingPhone(value);
                    break;
                case "billingEmail":
                    setBillingEmail(value);
                    break;
                default:
                    break;
            }
        }
    };

    const handleOnChangeWork = (e) => {
        if (!showWorkConfirmation) {
            const value = e.target.value;
            switch (e.target.name) {
                case "workFirstName":
                    setWorkFirstName(value);
                    break;
                case "workLastName":
                    setWorkLastName(value);
                    break;
                case "workAddress":
                    setWorkAddress(value);
                    break;
                case "workCity":
                    setWorkCity(value);
                    break;
                case "workState":
                    setWorkState(value);
                    break;
                case "workZip":
                    setWorkZip(value);
                    break;
                case "workPhone":
                    setWorkPhone(value);
                    break;
                case "workEmail":
                    setWorkEmail(value);
                    break;
                default:
                    break;
            }
        }
    };

    const handleLogoClick = () => {
        // set showCreateNew to false; opens main menu
        props.handleShowCreateNew(false);
    };

    const handleHelpClick = () => {
        props.handleShowCreateNew(false);
        props.handleShowLogin(true);
    };

    const handleCreateCustomerClick = () => {
        setshowBillingConfirmation(true);
    };

    const handleConfirmClick = () => {
        setshowBillingConfirmation(false);
        setShowBilling(false);
        setShowWorkLocation(true);
        setShowImportFromBilling(true);
    };

    const handleConfirmCancel = () => {
        setshowBillingConfirmation(false);
    };

    const handleCreateCustomerClick2 = () => {
        setShowWorkConfirmation(true);
    };

    const handleConfirmClick2 = () => {
        setShowWorkConfirmation(false);
        setShowWorkLocation(false);
        setShowNewInvoice(true);

    };
    const handleConfirmCancel2 = () => {
        setShowWorkConfirmation(false);
    };

    const handleImportConfirmClick = () => {
        // set work location equal to billing info
        setWorkFirstName(billingFirstName);
        setWorkLastName(billingLastName);
        setWorkAddress(billingAddress);
        setWorkCity(billingCity);
        setWorkState(billingState);
        setWorkZip(billingZip);
        setWorkPhone(billingPhone);
        setWorkEmail(billingEmail);
        setShowImportFromBilling(false);

        //TODO show invoice work/materials entry section for input
        // setShowWorkDetailsEntry(true)
        // setShowImportFromBilling(false);
        // setShowBilling(false);

        // send the customer billing/location info to dynamodb for storage
    };

    const handleImportConfirmCancel = () => {
        setShowImportFromBilling(false);
    };

    const handleSubmit = (customer) => {
        // create customer/invoice with graphql call
        const timeStamp = Date.parse(new Date());
        const payload = {
            name: `${billingFirstName} ${billingLastName}`,
            billAddress: billingAddress,
            billCity: billingCity,
            billZip: billingZip,
            billState: billingState,
            billPhone1: billingPhone,
            // billPhone2:
            billEmail1: billingEmail,
            workName: `${workFirstName} ${workLastName}`,
            workAddress,
            workCity,
            workState,
            workZip,
            workPhone,
            workEmail1: workEmail,
            createdAt: timeStamp,
            modified: timeStamp,
            invoice
        }
        API.graphql(graphqlOperation(createCustomers1, { input: payload })).then(response => {
            console.log("Customer successfully created: ", response);
            setShowNewInvoice(false);
            // alert("Customer/Invoice successfully created!");
            setShowSuccess(true);
            setTimeout(() => {
                setShowSuccess(false);
                props.handleSuccess(timeStamp);}, 2000);
        });
    }

    const handleBackClick = () => {
        if (showBilling) {
            {props.handleBackClick("newCustomer")}
        }
        else if (!showBilling && showWorkLocation) {
            setShowWorkLocation(false);
            setShowBilling(true);
        }
        else if (!showBilling && !showWorkLocation && showNewInvoice) {
            setShowNewInvoice(false);
            setShowWorkLocation(true);
        }
    }


    return (
        <div className="new-customer">
            <div className="header">
                {/* <div className="header-logo" onClick={handleLogoClick}></div> */}
                <div className="backwards button material-icons" onClick={() => handleBackClick()}>arrow_back</div>
                {/* <div className="help-button" onClick={handleHelpClick}>
                    Help
        </div> */}
            </div>
            <div className="container">
                {showBilling ? (
                    <Fragment>
                        <div className="title">New Customer Billing Information</div>
                        <input
                            className="billing-input"
                            name="billingFirstName"
                            value={billingFirstName}
                            onChange={handleOnChangeBilling}
                            placeholder="First Name"
                        ></input>
                        <input
                            className="billing-input"
                            name="billingLastName"
                            value={billingLastName}
                            onChange={handleOnChangeBilling}
                            placeholder="Last Name"
                        ></input>
                        <input
                            className="billing-input"
                            name="billingAddress"
                            value={billingAddress}
                            onChange={handleOnChangeBilling}
                            placeholder="Address"
                        ></input>
                        <input
                            className="billing-input"
                            name="billingCity"
                            value={billingCity}
                            onChange={handleOnChangeBilling}
                            placeholder="City"
                        ></input>
                        <input
                            className="billing-input"
                            name="billingState"
                            value={billingState}
                            onChange={handleOnChangeBilling}
                            placeholder="State"
                        ></input>
                        <input
                            className="billing-input"
                            name="billingZip"
                            value={billingZip}
                            onChange={handleOnChangeBilling}
                            placeholder="Zip"
                        ></input>
                        <input
                            className="billing-input"
                            name="billingPhone"
                            value={billingPhone}
                            onChange={handleOnChangeBilling}
                            placeholder="Phone Number"
                        ></input>
                        <input
                            className="billing-input"
                            name="billingEmail"
                            value={billingEmail}
                            onChange={handleOnChangeBilling}
                            placeholder="Email Address"
                        ></input>
                        <div className="submit-button" onClick={handleCreateCustomerClick}>
                            Confirm and Continue
                        </div>
                    </Fragment>
                ) : showWorkLocation ? (
                    <Fragment>
                        <div className="title">Location of Work Done</div>
                        <input
                            className="billing-input"
                            name="workFirstName"
                            value={workFirstName}
                            onChange={handleOnChangeWork}
                            placeholder="First Name"
                        ></input>
                        <input
                            className="billing-input"
                            name="workLastName"
                            value={workLastName}
                            onChange={handleOnChangeWork}
                            placeholder="Last Name"
                        ></input>
                        <input
                            className="billing-input"
                            name="workAddress"
                            value={workAddress}
                            onChange={handleOnChangeWork}
                            placeholder="Address"
                        ></input>
                        <input
                            className="billing-input"
                            name="workCity"
                            value={workCity}
                            onChange={handleOnChangeWork}
                            placeholder="City"
                        ></input>
                        <input
                            className="billing-input"
                            name="workState"
                            value={workState}
                            onChange={handleOnChangeWork}
                            placeholder="State"
                        ></input>
                        <input
                            className="billing-input"
                            name="workZip"
                            value={workZip}
                            onChange={handleOnChangeWork}
                            placeholder="Zip"
                        ></input>
                        <input
                            className="billing-input"
                            name="workPhone"
                            value={workPhone}
                            onChange={handleOnChangeWork}
                            placeholder="Phone Number"
                        ></input>
                        <input
                            className="billing-input"
                            name="workEmail"
                            value={workEmail}
                            onChange={handleOnChangeWork}
                            placeholder="Email Address"
                        ></input>
                        <div className="submit-button" onClick={handleCreateCustomerClick2}>
                            Confirm and Continue
            </div>
                    </Fragment>
                ) : null}
                <div
                    className={
                        showImportFromBilling ? "import visible" : "import invisible"
                    }
                >
                    {/* <div className="confirm-message3">This step can be skipped if the location where the work was done is the same as in the provided billing information.</div> */}
                    <div className="confirm-message4">
                        Is the work location information the same as the billing?
        </div>
                    <div className="confirm-button-yes" onClick={handleImportConfirmClick}>
                        Yes, it is
        </div>
                    <div className="confirm-button-no" onClick={handleImportConfirmCancel}>
                        No, it's not
        </div>
                </div>
                <div
                    className={
                        showBillingConfirmation ? "confirm visible" : "confirm invisible"
                    }
                >
                    {/* <div className="confirm-message">
                    This customer will be added to the database.
        </div> */}
                    <div className="confirm-message2">
                        Are you sure that all of their information was entered correctly?
        </div>
                    <div className="confirm-button-yes" onClick={handleConfirmClick}>
                        Yes, I'm sure
        </div>
                    <div className="confirm-button-no" onClick={handleConfirmCancel}>
                        Cancel
        </div>
                </div>
                <div
                    className={
                        showWorkConfirmation ? "confirm2 visible2" : "confirm2 invisible2"
                    }
                >
                    {/* <div className="confirm-message">
                    This customer will be added to the database.
        </div> */}
                    <div className="confirm-message2">
                        Are you sure that all of their information was entered correctly?
        </div>
                    <div className="confirm-button-yes" onClick={handleConfirmClick2}>
                        Yes, I'm sure
        </div>
                    <div className="confirm-button-no" onClick={handleConfirmCancel2}>
                        Cancel
        </div>
                </div>
                {showNewInvoice && <NewInvoice saveInvoice={setInvoice} handleBackClick={() => handleBackClick("newInvoice")} submit={handleSubmit} />}
                {showSuccess ? (
                    <div className="success">
                        <i className="material-icons">check_circle_outline</i>
                        <p>Customer/Invoice Successfully Created</p>
                    </div>
                ):null}
            </div>
        </div>
    );
}

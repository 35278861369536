import React from 'react'
import "./CustomerView.css";

const CustomerView = (props) => {

    const handleLogoClick = () => {
        props.handleShowCustomerView(false);
    }

    const renderCustomers = () => {
        const filterList = [];
        props.customers.sort((a, b) => (a.modified < b.modified ? 1 : -1));
        props.customers.forEach(customer => {
            console.log(customer);
            if (customer.name.toLowerCase().includes(props.customer.toLowerCase())) {
                let invoice = JSON.parse(customer.invoice);
                let total;
                let description;
                if (invoice) {
                    total = invoice.total.toFixed(2);
                    description = invoice.description;
                }
                const lastModified = new Date(parseInt(customer.modified))
                const day = lastModified.getDate();
                const month = lastModified.getMonth() + 1;
                const year = lastModified.getFullYear();
                filterList.push(
                    <div className="customer-line"
                        onClick={() => props.handleSelectedInvoice(customer)}>
                        <p>{customer.name}</p>
                        <p>{`${customer.workAddress} ${customer.workCity}`}</p>
                        <p>{description}</p>
                        <p>{total}</p>
                        <p>{`${month}/${day}/${year}`}</p>
                    </div>
                )
            }
        })
        return filterList;
    }

    const handleHelpClick = () => {

    }

    return (
        <div className="customer-view">
            <div className="header">
                {/* <div className="header-logo" onClick={handleLogoClick}></div> */}
                <div className="backwards button material-icons" onClick={() => props.handleBackClick("customerView")}>arrow_back</div>
                {/* <div className="help-button" onClick={handleHelpClick}>
                    Help
                        </div> */}
            </div>
            <div className="container">
                <div className="table-head">
                    <p>Customer Name:</p>
                    <p>Work Address:</p>
                    <p>Description:</p>
                    <p>Total:</p>
                    <p>Last Modified:</p>
                    {/* <p>Work Date</p> */}
                </div>
                {renderCustomers()}
            </div>
        </div>
    )
}

export default CustomerView

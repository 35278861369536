/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomers1 = /* GraphQL */ `
  query GetCustomers1($id: String!) {
    getCustomers1(id: $id) {
      id
      name
      billAddress
      billCity
      billZip
      billState
      billPhone1
      billPhone2
      billEmail1
      modified
      workAddress
      workCity
      workEmail1
      workName
      workPhone
      workState
      invoice
    }
  }
`;
export const listCustomers1S = /* GraphQL */ `
  query ListCustomers1S(
    $filter: TableCustomers1FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers1S(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        billAddress
        billCity
        billZip
        billState
        billPhone1
        billPhone2
        billEmail1
        modified
        workAddress
        workCity
        workEmail1
        workName
        workPhone
        workState
        invoice
      }
      nextToken
    }
  }
`;

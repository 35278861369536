import React from 'react'
import "./PrintPreview.css";

const PrintPreview = (props) => {
    const {
        billAddress,
        billCity,
        billEmail1,
        billEmail2,
        billPhone1,
        billPhone2,
        billState,
        billZip,
        id,
        invoice,
        modified,
        name,
        workAddress,
        workCity,
        workZip,
        workEmail1,
        workName,
        workPhone,
        workState
    } = props.invoice;

    const parsedInvoice = JSON.parse(invoice);

    return (
        <div className="print-preview-page">
            <div className="container">
                {/* <div className="logo"/> */}
                <h1>Wassing Landscaping</h1>
                <div className="billing">
                    <h2>Billing:</h2>
                    <p>Name: {name}</p>
                    <p>Address: {billAddress}</p>
                    <p>City: {billCity}</p>
                    <p>State: {billState}</p>
                    <p>Zip: {billZip}</p>
                    <p>Phone1: {billPhone1}</p>
                    {billPhone2 ? <p>Phone2: {billPhone2}</p> : null}
                    <p>Email1: {billEmail1}</p>
                    {billEmail2 ? <p>Email2: {billEmail2}</p> : null}
                </div>
                <div className="work">
                    <h2>Work Location:</h2>
                    <p>Name: {workName}</p>
                    <p>Address: {workAddress}</p>
                    <p>City: {workCity}</p>
                    <p>State: {workState}</p>
                    <p>Zip: {workZip}</p>
                    <p>Phone: {workPhone}</p>
                    <p>Email1: {workEmail1}</p>
                </div>
                <div className="invoice">
                    <h2>Work Performed:</h2>
                    <p>Description: {parsedInvoice.description}</p>
                    <p>Materials: ${(parsedInvoice.materials.quantity * parsedInvoice.materials.price).toFixed(2)}</p>
                    <p>Labor: ${(parsedInvoice.laborHours * parsedInvoice.laborRate).toFixed(2)} ({parsedInvoice.laborHours} hours @ ${parsedInvoice.laborRate}/hour)</p>
                    <p>Subtotal: ${(parsedInvoice.subTotal).toFixed(2)}</p>
                    <p>Tax: ${((parsedInvoice.taxRate / 100) * parsedInvoice.subTotal).toFixed(2)} ({parsedInvoice.taxRate}%)</p>
                    <p>Total: ${(parsedInvoice.total).toFixed(2)}</p>
                </div>
            </div>
        </div>
    )
}

export default PrintPreview

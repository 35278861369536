import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from "aws-amplify";
import config from "./Network/configure";
import './index.css';
// import Main from "./Main";
import * as serviceWorker from './serviceWorker';
import App from "./App";

Amplify.configure({
    Auth: {
        mandatorySignin: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    API: {
        endpoints: [
            {
                name: 'testApi',
                enpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION
            }
        ]
    }
})

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import InvoiceView from "./InvoiceView";
import { connect } from "react-redux";
import * as A from "../../constants/actions";

const mapStateToProps = (state) => ({
  items: state.items,
  cognitoUser: state.cognitoUser,
  customers: state.customers,
});

const mapDispatchToProps = (dispatch) => ({
  setCognitoUser: (user) => {
    dispatch({
      type: A.COGNITO_USER,
      payload: user,
    });
  },
  setCustomers: (payload) => {
      dispatch({
          type: A.CUSTOMERS,
          payload
      })
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceView);

import NewInvoice from "./NewInvoice";
import { connect } from "react-redux";
import * as A from "../../constants/actions";

const mapStateToProps = (state) => ({
  items: state.items,
  cognitoUser: state.cognitoUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCognitoUser: (user) => {
    dispatch({
      type: A.COGNITO_USER,
      payload: user,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewInvoice);

import React, { Component, Fragment } from "react";
import "./Login.css";
import { Auth } from "aws-amplify";
import {checkUser} from "Network/authentication";

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: "",
            password: "",
            setNewPassword: false,
            newPassword: "",
        };
    }

    componentDidMount() {
        checkUser().then(response => {
            this.props.setCognitoUser(response);
            this.props.setShowLoginScreen(false);
        }
        ).catch(error => {
            console.error("Error in check user mount:", error);
        })
    }
    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    loginButtonClick = async (event) => {
        const { userName, password } = this.state;
        if (userName === "") {
            this.setState({
                showUserNameError: true,
            });
            setTimeout(() => {
                this.setState({
                    showUserNameError: false,
                });
            }, 5000);
        }
        if (password === "")
            this.setState({
                showPasswordError: true,
            });
        setTimeout(() => {
            this.setState({
                showPasswordError: false,
            });
        }, 5000);

        try {
            const user = await Auth.signIn(userName, password);
            // .then(response => {
            //     console.log(response);
            // })
            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                if (!this.state.setNewPassword) {
                    alert("New password required");
                    this.setState({
                        setNewPassword: true,
                    });
                }
                if (this.state.newPassword !== "") {
                    const email = "joshwassing@live.com";
                    const loggedUser = await Auth.completeNewPassword(
                        user,
                        this.state.newPassword,
                        {
                            email,
                        }
                    );
                }
            } else {
                this.props.setCognitoUser(user);
                this.props.setShowLoginScreen(false);
            }
        } catch (e) {
            if (userName === "" && password === "")
                alert("Username is empty. Please enter your username.\nPassword is empty. Please enter your password");
            else if (userName === "")
                alert("Username is empty. Please enter your username.");
            else if (password === "")
                alert("Password is empty. Please enter your password");
            else
                alert(e.message);
        }
    };

    updateButtonClick = () => {
        return this.state.newPassword;
    };

    logoutButtonClick = () => {
        Auth.signOut()
            .then((data) => {
                this.props.setCognitoUser(null);
            })
            .catch((err) => console.log(err));
    };

    handleKeyDown = (e) => {
        if (e.key === "Enter")
            this.loginButtonClick();
    }
    render() {
        return (
            <div className="login">
                {!this.props.cognitoUser ? (
                    <Fragment>
                        <label>Username:</label>
                        <input
                            className={this.state.showUserNameError ? "username error" : "username"}
                            label="UserName:"
                            name="userName"
                            type="text"
                            value={this.state.userName}
                            onChange={this.onChange}
                        />
                        <label>Password:</label>
                        <input
                            className={this.state.showPasswordError ? "password error" : "password"}
                            label="Password:"
                            name="password"
                            type="password"
                            value={this.state.password}
                            onChange={this.onChange}
                            onKeyDown={this.handleKeyDown}
                        />
                        <div className="submit button" onClick={this.loginButtonClick}>
                            Login
            </div>
                        {this.state.setNewPassword ? (
                            <div className="new-password">
                                <input
                                    label="New password:"
                                    name="newPassword"
                                    type="password"
                                    value={this.state.newPassword}
                                    onChange={this.onChange}
                                />
                                {/* <div className="update button" onClick={this.updateButtonClick}>
                            Update password
                            </div> */}
                            </div>
                        ) : null}
                    </Fragment>
                ) : (
                        <div className="signed-in">
                            Hello, {this.props.cognitoUser.username}
                            <div
                                className="sign-out button"
                                onClick={() => this.logoutButtonClick()}
                            >
                                Signout
            </div>
                        </div>
                    )}
            </div>
        );
    }
}

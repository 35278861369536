import { createStore, applyMiddleware } from "redux";
import createSagaMiddleWare from "redux-saga";
import saga from "../Sagas";
import { createLogger } from "redux-logger";
import reducer from "./reducers";

const sagaMiddleWare = createSagaMiddleWare();
const middleWare = [sagaMiddleWare];
middleWare.push(
    createLogger({
        duration: true,
    })
);
const store = createStore(reducer, applyMiddleware(...middleWare));
sagaMiddleWare.run(saga);

export default store;
import React, { useEffect, useState } from 'react';
import "./Search.css";

export const Search = (props) => {

    const [customerList, setCustomerList] = useState(props.customers);
    const [customerSet, setCustomerSet] = useState({});
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCustomer, setSelectedCustomer] = useState("");

    const handleLogoClick = () => {
        props.handleShowSearch(false);
    }

    const handleHelpClick = () => {

    }

    useEffect(() => {
    }, [customerSet]);

    useEffect(() => {
        let tempCustomerObject = new Map();
        customerList.sort((a, b) => (a.modified < b.modified ? 1 : -1));
        customerList.forEach(customer => {
            let name = customer.name;
            if (!(tempCustomerObject.get(name))) {
                tempCustomerObject.set(name, { count: 1, entries: [] });
                let copyEntries = [...tempCustomerObject.get(name).entries];
                copyEntries.push(customer);
                tempCustomerObject.set(name, { count: 1, entries: copyEntries });
            }
            else {
                let copyEntries = [...tempCustomerObject.get(name).entries];
                copyEntries.push(customer);
                let copyCount = tempCustomerObject.get(name).count + 1;
                tempCustomerObject.set(name, { count: copyCount, entries: copyEntries });
            }
        })

        setCustomerSet(tempCustomerObject);
    }, []);

    const renderCustomers = () => {
        if (customerSet.size > 0) {
            const customerLineList = [];
            customerSet.forEach((value, key) => {
                if (searchTerm === "" ||
                    (key.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        (value.entries[0].billAddress.toLowerCase().includes(searchTerm.toLowerCase())))) {

                            let lastModified = 0;
                            value.entries.forEach(entry => {
                                if (entry.modified > lastModified)
                                    lastModified = entry.modified;
                            })
                            lastModified = new Date(parseInt(lastModified))
                            const day = lastModified.getDate();
                            const month = lastModified.getMonth() + 1;
                            const year = lastModified.getFullYear();
                    customerLineList.push(
                        <div className="customer-line"
                            onClick={() => props.handleSelectCustomer(key)}>
                            <p>{key}</p>
                            <p>{value.entries[0].billAddress}</p>
                            <p>{`${month}/${day}/${year}`}</p>
                            <p>{value.count}</p>
                        </div>
                    )
                }
            })
            return (customerLineList);
        }
    }

    const handleOnChange = (e) => {
        setSearchTerm(e.target.value);
    }

    return (
        <div className="search">
            <div className="header">
                {/* <div className="header-logo" onClick={handleLogoClick}></div> */}
                <div className="backwards button material-icons" onClick={() => props.handleBackClick("search")}>arrow_back</div>
                <input value={searchTerm} onChange={handleOnChange} placeholder="Search name or address" />
                {/* <div className="help-button" onClick={handleHelpClick}>
                    Help
                        </div> */}
            </div>
            <div className="container">
                <div className="table-head">
                    <p>Customer Name:</p>
                    <p>Bill Address:</p>
                    <p>Last Modified:</p>
                    <p>Number of Invoices:</p>
                </div>
                {renderCustomers()}
            </div>
        </div>
    )
}

export default Search;
import React, { useState, useEffect, Fragment } from "react";
import Amplify from "aws-amplify";
import "./Main.css";
import NewCustomer from "./views/NewCustomer/_NewCustomer";
import Login from "./Forms/Login/_Login";
// import Lookup from "./LookUp/_Lookup";
import Search from "./views/Search/_Search";
import { myAppConfig } from "Network/awsConfig";
import * as queries from "graphql/queries";
import { API, graphqlOperation, Auth } from "aws-amplify";
// import { SearchCard } from "Forms/Search/SearchCard/SearchCard";
import CustomerView from "views/CustomerView/_CustomerView";
import InvoiceView from "views/InvoiceView/_InvoiceView";

export default function Main(props) {
    const [showCreateExisting, setShowCreateExisting] = useState(false);
    const [showCreateNew, setShowCreateNew] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [displayName, setDisplayName] = useState("");
    const [showSearch, setShowSearch] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [selectedInvoice, setSelectedInvoice] = useState("");
    const [showcustomerView, setShowCustomerView] = useState(false);
    const [showInvoiceView, setShowInvoiceView] = useState(false);

    const {
        cognitoUser,
        customers,
        showLoginScreen,
        setShowLoginScreen,
    } = props;

    useEffect(() => {
        if (!cognitoUser)
            setShowLoginScreen(true);
        Amplify.configure(myAppConfig);
    }, [])

    useEffect(() => {
        if (cognitoUser && displayName !== cognitoUser.username) {
            let newDisplayName = (cognitoUser.username).split(".");
            newDisplayName = `${newDisplayName[0][0].toUpperCase()}${newDisplayName[0].slice(1).toLowerCase()}`;
            let currentTime = new Date().getHours();
            let timePhrase = "";
            if (currentTime < 12)
                timePhrase = "Good morning";
            else if (currentTime >= 12 && currentTime <= 16)
                timePhrase = "Good afternoon";
            else
                timePhrase = "Good evening";
            setDisplayName(`${timePhrase}, ${newDisplayName}!`);
        }
        if (cognitoUser && customers.length === 0) {
            handleRefreshClick();
        }
    }, [cognitoUser]);

    const handleRefreshClick = () => {
        Amplify.configure(myAppConfig);

        Auth.currentSession().then((response) => {
            console.log("sessh response", response);
        });

        const results = API.graphql(graphqlOperation(queries.listCustomers1S)).then(
            (results) => {
                props.setCustomers(results.data.listCustomers1S.items);
            }
        );
        console.log(results);
        // queries.listCustomers1S
    };

    const handleSelectCustomer = ((customer) => {
        setSelectedCustomer(customer);
        setShowSearch(false);
        setShowCustomerView(true);
    })

    const handleSelectInvoice = (invoice => {
        setSelectedInvoice(invoice);
        setShowCustomerView(false);
        setShowInvoiceView(true);
    })

    const handleBack = (backFrom) => {

        switch (backFrom) {
            case "invoiceView":
                setShowInvoiceView(false);
                setShowCustomerView(true);
                break;
            case "customerView":
                setShowCustomerView(false);
                setShowSearch(true);
                break;
            case "search":
                setShowSearch(false);
                break;
            case "newCustomer":
                setShowCreateNew(false);
                break;
        }

    }

    const handleSuccess = (timestamp) => {
        setShowCreateNew(false);
        //refresh to get new customer/invoice
        const results = API.graphql(graphqlOperation(queries.listCustomers1S)).then(
            (results) => {
                props.setCustomers(results.data.listCustomers1S.items);
            }
        );
        // setTimeout(() => {
        //     let newestCustomer;
        //     props.customers.forEach((customer) => {
        //         console.log("NEWEST:", newestCustomer);
        //         console.log("CUSTOMER:", customer);
        //         if (!newestCustomer)
        //             newestCustomer = customer;
        //         else if (customer.modified > newestCustomer.modified)
        //             newestCustomer = customer;
        //     })
        //     handleSelectCustomer(newestCustomer);

        // }, 1500);
    }

    return (
        <div className="main">
            {showCreateExisting ? (
                <div> </div>
            ) : // <CreateExisting/>
                showCreateNew ? (
                    <NewCustomer
                        handleShowCreateNew={setShowCreateNew}
                        handleBackClick={handleBack}
                        handleSuccess={handleSuccess}
                    />
                ) : showLoginScreen ? (
                    <Login />
                ) : showSearch ? (
                    <Search handleShowSearch={setShowSearch} handleSelectCustomer={handleSelectCustomer} handleBackClick={handleBack} />
                ) : showcustomerView ? (
                    <CustomerView handleShowCustomerView={setShowCustomerView} handleBackClick={handleBack} handleSelectedInvoice={handleSelectInvoice} customer={selectedCustomer} />
                ) : showInvoiceView ? (
                    <InvoiceView handleShowInvoiceView={setShowInvoiceView} handleBackClick={handleBack} invoice={selectedInvoice} handle/>
                ) :
                                (
                                    <Fragment>
                                        <div className="logo"></div>
                                        <h1>{displayName}</h1>
                                        <div className="menu">
                                            {/* <div
                                                className="menu-button"
                                                onClick={() => setShowCreateExisting(true)}
                                            >
                                                Create an invoice for an existing customer
            </div> */}
                                            <div
                                                className="menu-button"
                                                onClick={() => setShowCreateNew(true)}
                                            >
                                                New Customer
            </div>
                                            <div className="menu-button" onClick={() => setShowSearch(true)}>
                                                Existing Customer
            </div>
                                        </div>
                                    </Fragment>
                                )}
        </div>
    );
}

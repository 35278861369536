import React, { useState, useEffect, useRef, Fragment } from 'react'
import "./NewInvoice.css";

function NewInvoice(props) {
    const billCustomer = props.billCustomer;
    const workCustomer = props.workCustomer;

    // input values
    const [description, setDescription] = useState("");
    const [materials, setMaterials] = useState({ name: "", quantity: 0, price: 0 });
    const [materialInputs, setMaterialInputs] = useState([]);
    const [numberOfMaterialInputs, setNumberOfMaterialInputs] = useState(1);
    const [laborHours, setLaborHours] = useState("");
    const [laborRate, setLaborRate] = useState(80.00);
    const [subTotal, setSubTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [taxRate, setTaxRate] = useState(8.6);
    const [taxAmount, setTaxAmount] = useState(0);
    const inputRef = useRef();
    inputRef.current = new Map();
    let matName = "";
    let matQuantity = 0;
    let matPrice = 0;

    const handleOnChange = (e, key = null) => {
        const value = e.target.value;
        let newMaterials;
        switch (e.target.name) {
            case "description":
                setDescription(value);
                break;
            case "materialName":
                matName = value;
            // let newObject = new Object;
            // const numberOfLines = inputRef.current.size / 3;
            // for (let i = 0; i < numberOfLines; i++) {
            //     newObject = {
            //         name: inputRef.current.get(`${i}name`).value,
            //         quantity: inputRef.current.get(`${i}quantity`).value,
            //         value: inputRef.current.get(`${i}price`).value  }
            //     newMaterials.set(String(key), newObject);
            // }
            // console.log("NEW MATS:", newMaterials);
            // setMaterials(newMaterials);
            case "materialQuantity":
                matQuantity = Number(value);
            // newMaterials = {
            //     name: materials.name,
            //     quantity: value,
            //     price: materials.price
            // };
            // console.log("NEW MATS:", newMaterials);
            // setMaterials(newMaterials);
            case "materialPrice":
                matPrice = Number(value);
                // newMaterials = {
                //     name: materials.name,
                //     quantity: materials.quantity,
                //     price: value
                // }
                // console.log("NEW MATS:", newMaterials);
                // setMaterials(newMaterials);
                newMaterials = {
                    name: matName,
                    quantity: matQuantity,
                    price: matPrice
                }
                // console.log(newMaterials);
                setMaterials(newMaterials);
                break;
            case "laborHours":
                setLaborHours(value);
                break;
            case "laborRate":
                setLaborRate(value);
                break;
            case "subTotal":
                setSubTotal(value);
                break;
            case "taxRate":
                setTaxRate(value);
                break;
        }
    };

    useEffect(() => {
        const materialsList = [];

        for (let i = 0; i < numberOfMaterialInputs; i++) {
            materialsList.push(
                <div className="material-inputs">
                    <input
                        className="materials"
                        name="materialName"
                        key={`name${i}`}
                        ref={r => inputRef.current.set(`${i}name`, r)}
                        onChange={(e) => handleOnChange(e, i)}
                        placeholder="Material"
                    />
                    <input
                        className="materials small"
                        name="materialQuantity"
                        key={`quantity${i}`}
                        ref={r => inputRef.current.set(`${i}quantity`, r)}
                        onChange={(e) => handleOnChange(e, i)}
                        placeholder="Qty."
                    />
                    <input
                        className="materials small"
                        name="materialPrice"
                        key={`price${i}`}
                        ref={r => inputRef.current.set(`${i}price`, r)}
                        onChange={(e) => handleOnChange(e, i)}
                        placeholder="Price"
                    />
                    <i
                        className="material-icons add"
                        onClick={() => setNumberOfMaterialInputs(numberOfMaterialInputs + 1)}>
                        add_circle_outline
                    </i>
                    {i > 0 ? (<i
                        className="material-icons remove other"
                        onClick={() => {
                            setNumberOfMaterialInputs(numberOfMaterialInputs - 1);
                        }
                        }
                    >
                        remove_circle_outline</i>) :
                        (
                            <i className="material-icons remove invisible"></i>
                        )}
                </div>
            )
        }
        setMaterialInputs(materialsList);

    }, [numberOfMaterialInputs]);

    useEffect(() => {
        let cost = 0;
        // materialInputs
        // materials.forEach(material => {
        //     cost += (Number(material.quantity) * Number(material.price));
        // })
        cost += (Number(materials.price) * Number(materials.quantity));
        cost += laborRate * laborHours;
        setTaxAmount(cost * (taxRate / 100));
        setSubTotal(cost);
    }, [materials, laborRate, laborHours]);

    useEffect(() => {
        let newTotal = subTotal + taxAmount;
        setTotal(newTotal);
    }, [subTotal]);

    useEffect(() => {
        // console.log("MATERIALS", { materials });
        if (materials === "") {
            materials = null;
        }
        const invoice = JSON.stringify({ description, materials, laborHours, laborRate, laborRate, subTotal, total, taxRate });
        props.saveInvoice(invoice);

    }, [description, materials, laborHours, laborRate, laborRate, subTotal, total, taxRate]);

    return (
        <div className="new-invoice">
            {/* <div className="container"> */}
            <div className="title">
                Invoice
                </div>
            <label>Description:</label>
            <input
                className="description"
                name="description"
                value={description}
                onChange={handleOnChange}
                placeholder="Description"
            ></input>
            <div className="materials">
                {materialInputs}
            </div>
            <div className="labor">
                <label>Labor:</label>
                <input
                    className="labor-hours inline"
                    name="laborHours"
                    value={laborHours}
                    onChange={handleOnChange}
                    placeholder="Hours"
                ></input>
                <span>
                    @
                </span>
                <input
                    className="labor-rate inline small"
                    name="laborRate"
                    value={laborRate}
                    onChange={handleOnChange}
                    placeholder="Rate">
                </input>
                <span>
                    Per hour
                </span>
            </div>
            <div className="tax">
            <label>Tax Rate:</label>
            <input
                className="tax small"
                name="tax"
                value={taxRate}
                onChange={handleOnChange}
                placeholder="Tax"
            ></input>
            </div>
            <p>Materials: ${(materials.quantity * materials.price).toFixed(2)}</p>
            <p>Labor: ${(laborHours * laborRate).toFixed(2)}</p>
            <p>Subtotal: ${subTotal.toFixed(2)}</p>
            <p>Tax: ${taxAmount.toFixed(2)}</p>
            <p>Total: ${total.toFixed(2)}</p>
            <div className="submit-button" onClick={props.submit}>
                Confirm and Continue
            </div>
            {/* </div> */}
        </div>
    )
}

export default NewInvoice

import React, {useState, useEffect} from 'react';
import "./InvoiceView.css";
import EditInvoice from "views/EditInvoice/EditInvoice";
import PrintPreview from "views/PrintPreview/PrintPreview";
import *  as mutation from "graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import DeleteConfirm from "views/DeleteConfirm/DeleteConfirm";

export default function InvoiceView(props) {
    console.log("INVOICE:", props.invoice);

    const {
        billAddress,
        billCity,
        billEmail1,
        billEmail2,
        billPhone1,
        billPhone2,
        billState,
        billZip,
        id,
        invoice,
        modified,
        name,
        workAddress,
        workCity,
        workZip,
        workEmail1,
        workName,
        workPhone,
        workState
    } = props.invoice;

    const [showEditInvoice, setShowEditInvoice] = useState(false);
    const [showPrintPreview, setShowPrintPreview] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const [editChoice, setEditChoice] = useState("");

    const parsedInvoice = JSON.parse(invoice);

    const handleLogoClick = () => {
        props.handleShowInvoiceMenu(false);
    }

    const handleHelpClick = () => {

    }

    const handlePrintPreview = () => {
        setShowPrintPreview(true);
        setTimeout(() => window.print(), 1000);
        setTimeout(() => {
            setShowPrintPreview(false);
        }, 2000);
    }

    const handleUpdateBilling = () => {
        setEditChoice("billing");
        setShowEditInvoice(true);
    };

    const handleUpdateWorkLocation = () => {
        setEditChoice("workLocation");
        setShowEditInvoice(true);
    };

    const handleUpdateWorkPerformed = () => {
        setEditChoice("workPerformed");
        setShowEditInvoice(true);
    }

    const handleDelete = () => {
        const results = API.graphql(graphqlOperation(mutation.deleteCustomers1, {input: props.invoice.id})).then(
            (results) => {
                console.log("RSULTS::", results);
                props.setCustomers(results.data.listCustomers1S.items);
            })
            .catch(e => {
                console.error(e);
            });
            let customers = props.customers;
            customers = customers.filter((customer) => customer.id !== props.invoice.id);
            props.setCustomers(customers);

        props.handleBackClick("invoiceView");
    }

    return (
        <div className="invoice-view">
            <div className="header">
                {/* <div className="header-logo" onClick={handleLogoClick}></div> */}
                <div className="backwards button material-icons" onClick={() => props.handleBackClick("invoiceView")}>arrow_back</div>
                {/* <div className="update-invoice" onClick={handleUpdateInvoice}>Update Invoice</div> */}
                <div className="print-preview" onClick={handlePrintPreview}>Print Invoice</div>
                <div className="delete" onClick={() => setShowDeleteConfirm(true)}>Delete Invoice</div>
                {/* <div className="help-button" onClick={handleHelpClick}>
                    Help
                        </div> */}
            </div>
            <div className="container">
                <h1>Invoice View</h1>
                <div className="billing">
                    <h2>Billing:</h2>
                    <div className="update-billing edit-button" onClick={handleUpdateBilling}>Update Billing</div>
                    <p>Name: {name}</p>
                    <p>Address: {billAddress}</p>
                    <p>City: {billCity}</p>
                    <p>State: {billState}</p>
                    <p>Zip: {billZip}</p>
                    <p>Phone1: {billPhone1}</p>
                    {billPhone2 ? <p>Phone2: {billPhone2}</p> : null}
                    <p>Email: {billEmail1}</p>
                    {billEmail2 ? <p>Email2: {billEmail2}</p> : null}
                </div>
                <div className="work">
                    <h2>Work Location:</h2>
                    <div className="update-work edit-button" onClick={handleUpdateWorkLocation}>Update Work Location</div>
                    <p>Name: {workName}</p>
                    <p>Address: {workAddress}</p>
                    <p>City: {workCity}</p>
                    <p>State: {workState}</p>
                    <p>Zip: {workZip}</p>
                    <p>Phone: {workPhone}</p>
                    <p>Email: {workEmail1}</p>
                </div>
                <div className="invoice">
                    <h2>Work Performed:</h2>
                    <div className="update-work-performed edit-button" onClick={handleUpdateWorkPerformed}>Update Work Performed</div>
                    <p>Description: {parsedInvoice.description}</p>
                    <p>Materials: ${(parsedInvoice.materials.quantity * parsedInvoice.materials.price).toFixed(2)}</p>
                    <p>Labor: ${(parsedInvoice.laborHours * parsedInvoice.laborRate).toFixed(2)} ({parsedInvoice.laborHours} hours @ ${parsedInvoice.laborRate}/hour)</p>
                    <p>Subtotal: ${(parsedInvoice.subTotal).toFixed(2)}</p>
                    <p>Tax: ${((parsedInvoice.taxRate / 100) * parsedInvoice.subTotal).toFixed(2)} ({parsedInvoice.taxRate}%)</p>
                    <p>Total: ${(parsedInvoice.total).toFixed(2)}</p>
                </div>
                {showEditInvoice && <EditInvoice choice={editChoice} close={() => setShowEditInvoice(false)}/>}
                {showPrintPreview && <PrintPreview invoice={props.invoice} close={() => setShowPrintPreview(false)}/>}
                {showDeleteConfirm && <DeleteConfirm close={() => setShowDeleteConfirm(false)} accept={handleDelete}/>}
            </div>
        </div>
    )
}

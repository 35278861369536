export default {
    apiGateway: {
        REGION: 'us-west-2',
        URL: 'https://ojuzldy9te.execute-api.us-west-2.amazonaws.com/dev'
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID:"us-west-2_4xKSQYc2W",
        APP_CLIENT_ID: "2of9fec7a0c2bbsa4qsr2gr613",
        // IDENTITY_POOL_ID: "",
    }
};
import Main from "./Main";
import { connect } from "react-redux";
import * as A from "./constants/actions";

const mapStateToProps = (state) => ({
  customers: state.customers,
  cognitoUser: state.cognitoUser,
  showLoginScreen: state.showLoginScreen,
});

const mapDispatchToProps = (dispatch) => ({
  setCognitoUser: (payload) => {
    dispatch({
      type: A.COGNITO_USER,
      payload
    });
  },
  setShowLoginScreen: (payload) => {
    dispatch({
      type: A.SHOW_LOGIN_SCREEN,
      payload
    });
  },
  checkUser: () => {
      dispatch({
          type: A.CHECK_USER
      })
  },
  setCustomers: (payload) => {
      dispatch({
          type: A.CUSTOMERS,
          payload
      })
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);

import * as A from "../constants/actions";
import { combineReducers } from "redux";

const cognitoUser = (state = null, action) =>
  action.type === A.COGNITO_USER ? action.payload : state;

const customers = (state = [], action) =>
  action.type === A.CUSTOMERS ? action.payload : state;

const showLoginScreen = (state = false, action) =>
  action.type === A.SHOW_LOGIN_SCREEN ? action.payload : state;

const newlyCreatedCustomer = (state = null, action) =>
action.type === A.CREATED_CUSTOMER ? action.payload: state;

export default combineReducers({
  customers,
  cognitoUser,
  showLoginScreen,
  newlyCreatedCustomer,
});

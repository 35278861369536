import React from 'react'
import "./DeleteConfirm.css";

const DeleteConfirm = (props) => {
    return (
        <div className="delete-confirm">
            <h1>Delete this invoice?</h1>
            <div className="cancel-button" onClick={props.close}>Cancel</div>
            <div className="accept-button" onClick={props.accept}>Delete</div>

        </div>
    )
}

export default DeleteConfirm

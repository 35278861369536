/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomers1 = /* GraphQL */ `
  mutation CreateCustomers1($input: CreateCustomers1Input!) {
    createCustomers1(input: $input) {
        id
        name
        billAddress
        billCity
        billZip
        billState
        billPhone1
        billPhone2
        billEmail1
        workName
        workAddress
        workCity
        workState
        workZip
        workPhone
        workEmail1
        createdAt
        modified
        invoice
    }
  }
`;
export const updateCustomers1 = /* GraphQL */ `
  mutation UpdateCustomers1($input: UpdateCustomers1Input!) {
    updateCustomers1(input: $input) {
      id
      name
      billAddress
      billCity
      billZip
      billState
      billPhone1
      billPhone2
      billEmail1
      workName
      workAddress
      workCity
      workState
      workZip
      workPhone
      workEmail1
      createdAt
      modified
      invoice
    }
  }
`;
export const deleteCustomers1 = /* GraphQL */ `
  mutation DeleteCustomers1($input: DeleteCustomers1Input!) {
    deleteCustomers1(input: $input) {
      id
      name
      billAddress
      billCity
      billZip
      billState
      billPhone1
      billPhone2
      billEmail1
      workName
      workAddress
      workCity
      workState
      workZip
      workPhone
      workEmail1
    }
  }
`;

import React from 'react'
import "./EditInvoice.css";

const EditInvoice = (props) => {

    const renderBillingUpdate = () => {
        return (
            <div className="billing-update">
                <h2>Billilng</h2>
                <i className="material-icons close-button" onClick={props.close}>close</i>
                <input></input>
                <input></input>
                <input></input>
                <input></input>
                <input></input>
                <input></input>
                <input></input>
                <input></input>

            </div>
        )
    }

    const renderWorkLocationUpdate = () => {
        return (
            <div className="work-location-update">
                <h2>Work Location</h2>
                <i className="close-button material-icons" onClick={props.close}>close</i>
                <input></input>
                <input></input>
                <input></input>
                <input></input>
                <input></input>
                <input></input>

            </div>
        )
    }

    const renderWorkPerformedUpdate = () => {
        return (
            <div className="work-performed-update">
                <h2>Work Performed</h2>
                <i className="close-button material-icons" onClick={props.close}>close</i>
                <input></input>
                <input></input>
                <input></input>
                <input></input>
                <input></input>
                <input></input>

            </div>
        )
    }
    return (
        <div className="edit-invoice">
            {props.choice === "billing" && renderBillingUpdate()}
            {props.choice === "workLocation" && renderWorkLocationUpdate()}
            {props.choice === "workPerformed" && renderWorkPerformedUpdate()}


        </div>
    )
}

export default EditInvoice
